import { createStore } from 'vuex'

export default createStore({
  state: {
    qrData: null,
  },
  getters: {
    qrData: (state) => state.qrData
  },
  mutations: {
    setQrData(state, data) {
      state.qrData = data;
    }
  },
  actions: {
    updateQrData({ commit }, data) {
      commit('setQrData', data);
    }
  },
  modules: {
  }
})
