import ROLE from "@/constants/role.constant";
import { getFromLocal } from "@/helpers/local.helper";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/",
    name: "main-layout",
    component: () => import("../components/layouts/MainLayout.vue"),
    children: [
      {
        path: "form",
        name: "form",
        component: () => import("../views/FormView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "admin",
        name: "admin",
        component: () => import("../views/AdminView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "rekap",
        name: "rekap",
        component: () => import("../views/RekapView.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/qr-scan",
    name: "qr-scan",
    component: () => import("../views/QrScanView.vue"),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = getFromLocal();
  if (to.meta.requiresAuth && !isAuthenticated) {
    next("/login");
  } else if (to.path === "/login" && isAuthenticated || to.path === "/admin" && isAuthenticated.me?.username !== ROLE.ADMIN) {
    if (isAuthenticated.me?.username === ROLE.ADMIN) next("/admin");
    else next("/form");
  } else {
    next();
  }
});

export default router;
