const key = 'ips-monitoring-app';

const setToLocal = (value) => {
  localStorage.setItem(key, JSON.stringify(value));
  return isInLocal(key);
};

const setToLocalWithOld = (value) => {
  let oldData = getFromLocal();
  if (!oldData) oldData = {} ;

  const newData = { ...oldData, ...value };
  return setToLocal(newData);
};

const isInLocal = () => {
  return localStorage.getItem(key) !== null;
};

const getFromLocal = (prop = null) => {
  if (!isInLocal(key)) return null;

  const data = JSON.parse(localStorage.getItem(key));
  if (prop) return data[prop];
  return data;
};

const removeFromLocal = () => {
  localStorage.removeItem(key);
  return !isInLocal(key);
};

export { setToLocal, setToLocalWithOld, isInLocal, getFromLocal, removeFromLocal };